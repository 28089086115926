import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

export type Handler<C extends SagaContext = SagaContext> = (ctx: C) => Promise<void>

export type ContextCreator<C extends SagaContext = SagaContext> = (ctx: SagaContext) => C

interface SagaMiddleware extends Middleware {
  run(): void
}

export function createSagaMiddleware(rootHandler: Handler, ...contextCreators: ContextCreator[]) {
  const actionChannel = new Channel<Action>()

  const middleware: Middleware = store => {
    sagaMidlleware.run = () => run(store);

    return next => action => {
      const result = next(action)
      actionChannel.put(action)
      return result
    }
  }

  const sagaMidlleware: SagaMiddleware = Object.assign(middleware, {
    run() {
      throw new Error('Before running a Saga, you must mount the Saga middleware on the Store using applyMiddleware')
    },
  })

  function run(store: MiddlewareAPI) {
    const standardCtx = {
      ...store,
      takeAny() {
        return actionChannel.get()
      },
    }

    const ctx = contextCreators.reduceRight((c, creator) => creator(c), standardCtx)

    rootHandler(ctx).catch(e => console.error('Unhandled rejection', e))
  }

  return sagaMidlleware;
}

export const all = (...handlers: Handler<any>[]): Handler =>
  async (ctx) => { await Promise.all(handlers.map(handler => handler(ctx))) }

export async function take(ctx: SagaContext, type: string) {
  for (;;) {
    const action = await ctx.takeAny();
    if (action.type === type) {
      return action
    }
  }
}

export const takeEvery = <C extends SagaContext = SagaContext, A extends Action = AnyAction>(type: string, handler: (ctx: C, action: A) => Promise<void>): Handler<C> =>
  async (ctx) => {
    for (;;) {
      const action = await take(ctx, type)
      handler(ctx, action as A) // don't await (essentially fork)
    }
  }

export interface SagaContext<S = any> extends MiddlewareAPI<Dispatch, S> {
  takeAny(): Promise<Action>
}

export class Channel<M> {
  private promise!: Promise<M>
  private resolve!: (msg: M) => void

  constructor() {
    this.recreate()
  }

  public get() {
    return this.promise
  }

  public put(msg: M) {
    setTimeout(() => {
      this.resolve(msg)
      this.recreate()
    }, 0)
  }

  private recreate() {
    this.promise = new Promise((resolve) => {
      this.resolve = resolve
    })
  }
}

// const ch = new Channel<string>()

// ch.get().then(x => console.warn('1', x))
// ch.get().then(x => {
//   console.warn('2', x)
//   ch.get().then(x => console.warn('3', x))
// })

// ch.put('one')
// ch.put('two')
// ch.put('three')
