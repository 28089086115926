import { CommandBarButton, Persona, PersonaSize, Text } from 'office-ui-fabric-react';
import React, { useCallback, useState } from 'react';
import { useSelector } from '../../store/utils';
import { MeControl } from '../MeControl/MeControl';
import './Header.css';

interface HeaderProps {
    onLogout: () => void
}

export const Header: React.FC<HeaderProps> = ({onLogout}: HeaderProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const openPanel = useCallback(() => setIsOpen(true), []);
    const dismissPanel = useCallback(() => setIsOpen(false), [])

    const accountResult = useSelector(s => s.auth.account)

    if (!accountResult.isSuccess || accountResult.getValueOrThrow() === null) {
        return null
    }

    const parts = accountResult.getValueOrThrow().name.split(" ")
    const givenName = parts[0]
    const familyName = parts[1]


    return <div className='header-root'>
        <div className='title'>
            <Text className='title-text' variant='xLarge'>IPFX Service Directory</Text>
        </div>

        <div className='padding' />

        <div className='me-button-container'>
            <div className='me-control-button'>
                {/* <IconButton iconProps={{iconName: 'Contact' }}/> */}
                <CommandBarButton className='me-button' onClick={openPanel}>
                    <Persona size={PersonaSize.size28} />
                </CommandBarButton>
            </div>
        </div>

        <MeControl isMeOpen={isOpen} logOut={onLogout} hideMe={dismissPanel} givenName={givenName} familyName={familyName}
                userName={accountResult.getValueOrThrow().userName}/>
    </div>
}
