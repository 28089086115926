
import { all } from "../async-saga/async-saga";
import { services } from "../services";
import { actions } from "../types";
import { takeEvery } from "./async-saga-types";

const fetchChannelConnections = takeEvery(actions.fetchChannelConnections.request, async function (ctx) {
    try {
        if (!services.apiClient) {
            console.error("No API client defined")
            return
        }
        const result = await services.apiClient.fetchChannelConnections()
        ctx.dispatch(actions.fetchChannelConnections.success(result))
    } catch (e) {
        ctx.dispatch(actions.fetchChannelConnections.failure(e))
    }
})


export default all(
    fetchChannelConnections,
)