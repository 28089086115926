import { mergeStyles } from '@uifabric/styling';
import { memoizeFunction } from '@uifabric/utilities';

export interface IAppPanelClassNames {
  root: string;
  headerNavigation: string;
  header: string;
  headerText: string;
}

export const getClassNames = memoizeFunction(
  (isContentFullBleed: boolean = false): IAppPanelClassNames => {
    return {
      root: mergeStyles({
        top: '50px !important', // TODO: Figure out specificity issue
        selectors: {
          '.ms-Panel-content': isContentFullBleed
            ? {
                padding: 0
              }
            : null
        }
      }),
      header: mergeStyles({
        display: 'flex'
      }),
      headerText: mergeStyles({
        flex: '1'
      }),
      headerNavigation: mergeStyles({
        justifyContent: 'flex-end'
      })
    };
  }
);

