import { connectRouter } from "connected-react-router";
import { History } from 'history';
import { combineReducers } from "redux";
import auth from "./auth";
import channelConnections from "./channelConnections";

export function createRootReducer(history: History) {
    return combineReducers({
        router: connectRouter(history),
        auth,
        channelConnections
    })
}