
// type ApiResult<T> = {
//     isEmpty: true
// } | {
//     isEmpty: false
// } & ({
//         isLoading: true
//     } | {
//         isLoading: false
//         value?: never
//         error: ApiError
//     } | {
//         isLoading: false
//         value: T
//         error?: never
//     })

export class ApiResult<T> {
    private _value?: T = undefined
    private _apiError?: ApiError = undefined
    private _isLoading: boolean = false
    private _isEmpty: boolean = true

    public static success<T>(value: T): ApiResult<T> {
        const result = new  ApiResult<T>()
        result._value = value
        result._isEmpty = false
        return result
    }

    public static failed<T>(error: Error): ApiResult<T> {
        const result = new  ApiResult<T>()
        result._apiError = {exception: error, message: error.message}
        result._isEmpty = false
        return result
    }

    public static loading<T>(): ApiResult<T> {
        const result = new  ApiResult<T>()
        result._isLoading = true
        result._isEmpty = false
        return result
    }

    public static default<T>(): ApiResult<T> {
        const result = new  ApiResult<T>()
        result._isEmpty = true
        return result
    }

    public getValueOrThrow() {
        if (this._value !== undefined) {
            return this._value
        }
        throw Error("Cannot access value for failed result")
    }

    public getError(): ApiError {
        if (this._apiError) {
            return this._apiError
        }
        throw Error("Cannot access error for successfull result")
    }

    get isLoaded(): boolean {
        return !this._isEmpty
    }

    get isEmpty(): boolean {
        return this._isEmpty
    }

    get isLoading(): boolean {
        return this._isLoading
    }

    get isSuccess(): boolean {
        return !this.isLoading && this._value !== undefined
    }

    get isFailure(): boolean {
        return !this.isLoading && this._apiError !== undefined
    }

}

export interface ApiError {
    exception?: Error
    problemDetails?: ProblemDetails
    message?: string
}


export interface ProblemDetails {
    api_error_code: string;
    detail?: string;
    instance: string;
    status: number;
    title: string;
    type: string;
}