import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createAuthedAccount } from '../store/actions';
import { useSelector } from '../store/utils';

export function useAzureAdAuth() {
    const { account } = useSelector(s => s.auth)
    const dispatch = useDispatch()
    useEffect(() => {
        if (account.isEmpty) {
            dispatch(createAuthedAccount.request())
        }
    }, [account.isEmpty, dispatch])

    return account;
}
