import { ImplicitMSALAuthenticationProvider } from "@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider";
import { MSALAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions";
import { AuthError, AuthResponse } from "msal";
import { actions } from "..";
import { ApiClient } from "../../api/ApiClient";
import { createAADClient } from "../../auth/azure-ad";
import { clientConfig } from "../../auth/clientConfig";
import { createGraphClient } from "../../graph/graph";
import { services } from "../services";
import { all } from "./async-saga";
import { takeEvery } from "./async-saga-types";

const createAuthedAccount = takeEvery(actions.createAuthedAccount.request, async function (ctx) {
    try {
        const config = clientConfig();
        const client = createAADClient(config)
        services.aadClient = client
        services.graphClient = createGraphClient(client.msalApplication, config.graphScopes)
        const providerOptions = new MSALAuthenticationProviderOptions(config.apiScopes);
        const provider = new ImplicitMSALAuthenticationProvider(client.msalApplication, providerOptions)
        services.apiClient = ApiClient.createWithAuthProvider(config.apiBaseUrl, provider)

        console.log("Created accounts and providers")

        client.handleRedirectCallback((authErr: AuthError, response?: AuthResponse) => {
            if (!response) {
                 // silent renew failed should send this to the store and raise error to ui
                 console.error("AAD silent renew failed:", authErr)
            } else {
                // update account with new details
                ctx.dispatch(actions.createAuthedAccount.success(response!.account))
            }
        })

        console.log("About to call client.acquireSilent()")
        const token = await client.acquireSilent(config.apiScopes)
        console.log("client.acquireSilent() returned token=", token)
        if (token) {
            const account = client.getLogin()!
            ctx.dispatch(actions.createAuthedAccount.success(account))
        } else {
            console.log("no token acquire via redirect", token)
            console.log("login scopes", config.graphScopes.concat(config.apiScopes))
            console.log("redirect scopes", config.graphScopes)
            client.acquireRedirect(config.graphScopes.concat(config.apiScopes), config.graphScopes)
        }
    } catch (e) {
        console.error("Error in createAuthedAccount()", e)
        ctx.dispatch(actions.createAuthedAccount.failure(e))
    }
})

const logout = takeEvery(actions.logout, async function (ctx) {
    try {
        services.aadClient!.logout()
    } catch (e) {
        console.error("logout failed", e)
    }
})

export default all(
    createAuthedAccount,
    logout
)
