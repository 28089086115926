import { ActionCreator, EmptyAction, getType, PayloadAction, TypeConstant } from 'typesafe-actions';
import { RootState } from '../types';
import { Handler, SagaContext as AnyContext, takeEvery as takeEveryRaw } from './async-saga';

type SagaContext = AnyContext<RootState>
type TakeEveryHandler<A> = (ctx: SagaContext, action: A) => Promise<void>

interface TakeEvery {
  <T extends TypeConstant, P>(actionCreator: (...args: any) => PayloadAction<T, P>, handler: TakeEveryHandler<PayloadAction<T, P>>): Handler
  <T extends string>(actionCreator: (...args: any) => EmptyAction<T>, handler: TakeEveryHandler<EmptyAction<T>>): Handler
}

export const takeEvery: TakeEvery = <T extends TypeConstant, P>(actionCreator: ActionCreator<T>, handler: TakeEveryHandler<PayloadAction<T, P>>): Handler =>
  takeEveryRaw(getType(actionCreator), (ctx, action) => handler(ctx, action as PayloadAction<T, P>))

export function createSagaContext(ctx: AnyContext): SagaContext {
  return ctx
}

interface Take {
  <T extends TypeConstant, P>(ctx: SagaContext, actionCreator: (...args: any) => PayloadAction<T, P>): Promise<PayloadAction<T, P>>
  <T extends string>(ctx: SagaContext, actionCreator: (...args: any) => EmptyAction<T>): Promise<EmptyAction<T>>
}

export const take: Take = async <T extends TypeConstant, P>(ctx: SagaContext, actionCreator: ActionCreator<T>): Promise<PayloadAction<T, P>> => {
  for (; ;) {
    const action = await ctx.takeAny();
    if (action.type === getType(actionCreator)) {
      return action as PayloadAction<T, P>;
    }
  }
}

export async function waitForState<T>(ctx: SagaContext, func: (state: RootState) => T): Promise<NonNullable<T>>
export async function waitForState<T>(ctx: SagaContext, func: (state: RootState) => T, predicate: (value: T) => boolean): Promise<T>
export async function waitForState<T>(ctx: SagaContext, func: (state: RootState) => T, predicate: (value: T) => boolean = value => Boolean(value)): Promise<T> {
  for (;;) {
    const res = func(ctx.getState())
    if (predicate(res)) {
      return res
    }
    await ctx.takeAny()
  }
}
