import moment from 'moment';
import { DetailsList, DetailsListLayoutMode, IColumn, ITextStyles, SelectionMode, Stack, Text } from 'office-ui-fabric-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SubscriberInfo } from '../../models';
import { actions } from '../../store';
import { useSelector } from '../../store/utils';
import './ChannelSubscribers.css';

interface Props {
}

interface Data {
    items: SubscriberInfo[]
    columns: IColumn[]
}

export const ChannelSubscribers: React.FC<Props> = (props: Props) => {
    const { subscribers } = useSelector(s => s.channelConnections)

    const dispatch = useDispatch()

    useEffect(() => {
        if (subscribers.isEmpty) {
            dispatch(actions.fetchChannelConnections.request())
        }
    }, [subscribers, dispatch])

    const [sort, setSort] = useState({key: "subscriptionName", isDesc: false})

    const handleColumnClick = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        setSort(s => s.key === column.key ? {...s, isDesc: !s.isDesc} : {key: column.key, isDesc: false})   
    }, [])

    const data = useMemo(() => {
        return getData(subscribers.isSuccess ? subscribers.getValueOrThrow() : [], sort.key, sort.isDesc, handleColumnClick)
    }, [handleColumnClick, sort, subscribers])

    const titleTextStyles: ITextStyles = {
        root: {
            fontSize: 28,
            marginBottom: '1rem'
        }
    }

    return (
        <Stack>
            <Text styles={titleTextStyles}>Channel Subscribers</Text>
            <DetailsList
                items={data.items}
                compact={false}
                columns={data.columns}
                selectionMode={SelectionMode.none}
                getKey={getKey}
                setKey="none"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                isHeaderVisible={true}
            />
        </Stack>
    )
}

function getData(base: SubscriberInfo[], sortKey: string, sortDesc: boolean, onColumnClick?: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void): Data {
    const newColumns: IColumn[] = getColumns(onColumnClick);
    const currColumn = newColumns.find(currCol => sortKey === currCol.key)!;
    newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = sortDesc
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
    });
    const newItems = copyAndSort(base, currColumn.fieldName!, currColumn.isSortedDescending);

    return { items: newItems, columns: newColumns }
}

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function getColumns(onColumnClick?: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void): IColumn[] {
    return [
        {
            key: 'subscriptionName',
            name: 'Name',
            fieldName: 'subscriptionName',
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            minWidth: 20,
            maxWidth: 150,
            onColumnClick: onColumnClick,
            data: 'string'
        },
        {
            key: 'host',
            name: 'Host',
            fieldName: 'host',
            minWidth: 20,
            maxWidth: 90,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true
        },
        {
            key: 'connectionId',
            name: 'Connection ID',
            fieldName: 'connectionId',
            minWidth: 70,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true
        },
        {
            key: 'lastNotificationReceivedUtc',
            name: 'Last Notification',
            fieldName: 'lastNotificationReceivedUtc',
            minWidth: 100,
            maxWidth: 180,
            isResizable: true,
            data: 'number',
            onRender: (item: SubscriberInfo) => {
                return renderDateTime(item.lastNotificationReceivedUtc)
            },
            onColumnClick: onColumnClick,
            isPadded: true
        },
        {
            key: 'connectedUtc',
            name: 'Connected',
            fieldName: 'connectedUtc',
            minWidth: 100,
            maxWidth: 180,
            isResizable: true,
            data: 'number',
            onRender: (item: SubscriberInfo) => {
                return renderDateTime(item.connectedUtc)
            },
            onColumnClick: onColumnClick,
            isPadded: true
        },
        {
            key: 'notificationsReceived',
            name: 'Notifs Recved',
            fieldName: 'notificationsReceived',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'number',
            onColumnClick: onColumnClick,
            isPadded: true
        }
    ]
}

function getKey(item: any, index?: number): string {
    return item.key;
}

function renderDateTime(value?: string) {
    if (value) {
        const time = moment.utc(value).local()
        time.local()
        return <span>{time.format('MMMM Do YYYY, h:mm:ss a')}</span>
    }
    return <span>Never</span>
}


