import { Account, AuthError, AuthResponse } from 'msal';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { SubscriberInfo } from '../models';

export const createAuthedAccount = createAsyncAction('CREATE_AUTHED_ACCOUNT_REQUEST', 'AUTHED_ACCOUNT_SUCCESS', 'CREATE_AUTHED_ACCOUNT_FAILURE')
                                                <undefined, Account, Error|AuthError>()

export const fetchChannelConnections = createAsyncAction(
                'FETCH_CHANNEL_CONNECTIONS_REQUEST', 
                'FETCH_CHANNEL_CONNECTIONS_SUCCESS',
                'FETCH_CHANNEL_CONNECTIONS_FAILURE')
                <undefined, SubscriberInfo[], Error>()


export const authResponse = createAction('AUTH_RESPONSE')<AuthResponse>();
export const logout = createAction('LOGOUT')();


