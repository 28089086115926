import { ApiClient } from '../api/ApiClient';
import { AzureAdClient } from '../auth/azure-ad';
import { GraphClient } from '../graph/graph';

export interface Services {
    aadClient?: AzureAdClient
    graphClient?: GraphClient
    apiClient?: ApiClient
}

export const services: Services = {

};