import { createBrowserHistory } from 'history';
import * as actions from './actions';
import { configureStore } from './configureStore';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;

export const history = createBrowserHistory({
    basename: baseUrl
  });
  
export const store = configureStore({}, history);

export { actions };

