import { FluentCustomizations } from '@uifabric/fluent-theme';
import { initializeIcons } from '@uifabric/icons';
import { ConnectedRouter } from 'connected-react-router';
import { Customizer, mergeStyles } from 'office-ui-fabric-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { App } from './components/App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { history, store } from './store';

initializeIcons();

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});


ReactDOM.render(
  <Customizer {...FluentCustomizations}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </Customizer>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
