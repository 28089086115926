import { push } from 'connected-react-router';
import { INavLink, INavLinkGroup, Nav } from 'office-ui-fabric-react';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import './AppNav.css';

export const AppNav: React.FC = () => {
    const dispatch = useDispatch()

    const handleLinkClick = useCallback((ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        if (ev) {
            ev.preventDefault();
        }
        if (item && item.key) {
            dispatch(push(item.url))
        }
    }, [dispatch])

    return <div className='sidebar'>
        <Nav
            groups={getLinks()}
            styles={{ root: { paddingTop: '16px' } }}
            onLinkClick={handleLinkClick}
        />
    </div>
}

function getLinks() {
    const links: INavLinkGroup[] = [
        {
            links: [
                {
                    key: 'channel-connections',
                    name: 'Connections',
                    icon: 'System',
                    url: '/channel-subscribers'
                }
            ]
        }
    ];

    return links
}


