import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import * as actions from './actions';
import rootHandler from './async-saga';
import { createSagaMiddleware } from './async-saga/async-saga';
import { createSagaContext } from './async-saga/async-saga-types';
import { createRootReducer } from './reducers';
import { RootState } from './types';
import { composeEnhancers } from './utils';
export { actions };



const loggerMiddleware = createLogger();

const sagasMiddleware = createSagaMiddleware(rootHandler, createSagaContext)


export function configureStore(initialState: Partial<RootState>, history: History) {

  const middlewares = [sagasMiddleware, loggerMiddleware, routerMiddleware(history)];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(createRootReducer(history), initialState!, enhancer);

  sagasMiddleware.run();

  return store;
}
