import { Fabric, MessageBar, MessageBarType, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAzureAdAuth } from '../../auth/useAzureAdAuth';
import { logout } from '../../store/actions';
import { AppNav } from '../AppNav';
import { ChannelSubscribers } from '../ChannelSubscribers';
import { Header } from "../Header";
import './App.css';

export const App: React.FC<{}> = (props: {}) => {

  const dispatch = useDispatch()
  const account = useAzureAdAuth()

  const handleLogout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const [errorMessage] = useState<string>()


  return (
    <Fabric className='app-root'>
      <div className='header-container'>
        <Header onLogout={handleLogout} />
        {/* <StandardAppBar appTitle="My Profile" theme={theme} /> */}
      </div>
      <>
        {account.isLoading && <Spinner label="Signing in..." size={SpinnerSize.large} ariaLive="assertive" labelPosition="bottom" className='login-spinner' />}
        {account.isSuccess && <AppBody errorMessage={errorMessage} />}
      </>
    </Fabric>
  );
}

interface BodyProps {
  errorMessage?: string
}

const AppBody: React.FC<BodyProps> = ({ errorMessage }: BodyProps) => {
  if (errorMessage) {
    return (<MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
      {errorMessage}
    </MessageBar>)
  }

  return (<div className='app-body'>
    <AppNav />
    <main className='app-content' >
      <Switch>
        <Redirect exact from='/' to='/channel-subscribers' />
        <Route path='/channel-subscribers'>
          <ChannelSubscribers />
        </Route>
      </Switch>
    </main>
  </div>)
}

