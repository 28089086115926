import { BaseComponent, classNamesFunction, css, IStyleFunctionOrObject } from '@uifabric/utilities';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { IPanelProps, IPanelStyleProps, IPanelStyles, Panel } from 'office-ui-fabric-react/lib/components/Panel';
import * as React from 'react';
import { getClassNames } from './AppPanel.classNames';


interface IAppPanelState {}
interface IAppPanelProps extends IPanelProps { 
  isOpen: boolean
  isContentFullBleed: boolean
  className?: string;
  onDismiss: () => void
  headerText: string
  headerClassName?: string
  componentId: string
  styles?: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles>
  hasCloseButton: boolean
}

export const getPanelClassNames = classNamesFunction<
  IPanelStyleProps,
  IPanelStyles
>();

/**
 * AppPanel represents a panel that leaves the app bar still visible
 */
export class AppPanel extends BaseComponent<IAppPanelProps, IAppPanelState> {
  public render(): React.ReactNode {
    const { children } = this.props;
    const classNames = getClassNames(this.props.isContentFullBleed);

    return (
      <Panel
        {...this.props}
        hasCloseButton={false}
        isLightDismiss={true}
        className={css(classNames.root, this.props.className)}
        onRenderHeader={this._renderHeader}
        onRenderNavigation={this.onRenderNavigation}
      >
        {children}
      </Panel>
    );
  }

  public dismiss = (): void => {
    this.props.onDismiss();
  }

  public onRenderNavigation = () => null;

  private _renderHeader = (
    props?: IPanelProps,
    defaultRender?: (props?: IAppPanelProps) => JSX.Element | null
  ): JSX.Element | null => {
    const {
      headerText,
      componentId,
      headerClassName = '',
      styles,
      theme
    } = props!;
    const classNames = getClassNames();
    const panelClassNames = getPanelClassNames(styles, { theme: theme as any });

    if (headerText) {
      return (
        <div
          className={css(
            'ms-Panel-header',
            panelClassNames.header,
            classNames.header
          )}
        >
          <p
            className={css(
              'ms-Panel-headerText',
              panelClassNames.headerText,
              classNames.headerText,
              headerClassName
            )}
            id={componentId + '-headerText'}
            role="heading"
            aria-level={1}
          >
            {headerText}
          </p>
          {this.props.hasCloseButton !== false ? (
            <IconButton
              className={classNames.headerNavigation}
              iconProps={{ iconName: 'Cancel' }}
              onClick={this.dismiss}
            />
          ) : null}
        </div>
      );
    }
    return null;
  }
}

