import { Account, Logger, ServerError, UserAgentApplication } from 'msal';
import { authResponseCallback } from 'msal/lib-commonjs/UserAgentApplication';

export interface AzureAdClientOptions {
   clientId: string
   redirectUrl: string
   authority?: string,
   postLogoutRedirectUri?: string
}

export type AzureAdClient  = ReturnType<typeof createAADClient>

export function createAADClient({ clientId, authority, redirectUrl, postLogoutRedirectUri }: AzureAdClientOptions) {

   const app = new UserAgentApplication({
      auth: {
         clientId,
         authority,
         redirectUri: redirectUrl.startsWith('http') ? redirectUrl : window.location.origin + redirectUrl,
         navigateToLoginRequestUrl: false,
         postLogoutRedirectUri: postLogoutRedirectUri
      },
      system: { logger: new Logger((level, message) => console.log(level, message)) },
      cache: { cacheLocation: 'localStorage' },
   })

   const me = {
      getLogin,
      acquirePopup,
      acquireRedirect,
      acquireSilent,
      handleRedirectCallback,
      isCallback,
      msalApplication: app,
      logout() {
         app.logout()
      }
   }

   function isCallback() {
      return app.isCallback(window.location.hash)
   }

   function handleRedirectCallback(authCallback: authResponseCallback) {
      app.handleRedirectCallback(authCallback)
   }

   async function acquireSilent(scopes: string[]) {
      const authRequest = { scopes, authority }

      try {
         if (!app.getAccount()) { return null }
         return await app.acquireTokenSilent(authRequest)
      } catch (error) {
         console.error(error)
         if (error instanceof ServerError) {
            if (error.errorMessage.includes('interaction_required') || error.errorCode === 'consent_required') {
               return null
            }
         }
         return null
      }
   }

   async function acquireTokenPopup(scopes: string[]) {
      const authRequest = { scopes, authority }

      try {
         await app.loginPopup(authRequest)
         return await app.acquireTokenPopup(authRequest)
      } catch (error) {
         console.error(error)
         throw error
      }
   }

   async function acquirePopup(scopes: string[]) {
      return (await acquireSilent(scopes)) || (await acquireTokenPopup(scopes))
   }

   function acquireRedirect(loginScopes: string[], redirectScopes: string[]) {
      const acc = app.getAccount()
      console.log("acquireRedirect():", acc)
      if (!app.getAccount()) {
         const authRequest = { scopes: loginScopes, authority }
         console.log("authRequest():", authRequest)
         app.loginRedirect(authRequest)
      } else {
         const authRequest = { scopes: redirectScopes, authority }
         console.log("authRequest():", authRequest)
         app.acquireTokenRedirect(authRequest)
      }
   }

   function getLogin(): Account | null {
      return app.getAccount()
   }

   return me
}

export function isAzureAdCallback(hash: string = window.location.hash) {
   try {
      if (hash.startsWith('#')) {
         hash = hash.substr(1)
      }
      return new URLSearchParams(hash).has('state')
   } catch (e) {
      return false
   }
}
