import { AnyAction } from "redux";
import { ActionCreator, getType, PayloadActionCreator } from "typesafe-actions";
import { ApiResult } from "./ApiResult";

interface ApiAction<T> {
    request: ActionCreator
    success: PayloadActionCreator<string, T>
    failure: PayloadActionCreator<string, Error>
}

export default function createApiReducer<T>(apiAction: ApiAction<T>) {
    return (state: ApiResult<T> = ApiResult.default(), action: AnyAction): ApiResult<T> => {
        switch (action.type) {
            case getType(apiAction.request):
                return ApiResult.loading()
            case getType(apiAction.success):
                return ApiResult.success(action.payload)
            case getType(apiAction.failure):
                return ApiResult.failed(action.payload)
            default:
                return state;
        }
    }
}