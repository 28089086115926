import { SubscriberInfo } from '../models';
import { urlJoin } from '../utils/urlJoin';
import { AuthenticationProvider } from './http/AuthenticationProvider';
import { Context } from './http/Context';
import { HTTPClient } from './http/HTTPClient';
import { HTTPClientFactory } from './http/HTTPClientFactory';
import { RequestMethod } from './http/RequestMethod';

export interface ApiClientOptions {
    authProvider: AuthenticationProvider;
    baseUrl: string;
}

export class ApiClient {

    private httpClient: HTTPClient
    private options: ApiClientOptions;

    public static create(clientOptions: ApiClientOptions): ApiClient {
        return new ApiClient(clientOptions);

    }

    public static createWithAuthProvider(baseUrl: string, authProvider: AuthenticationProvider): ApiClient {
        const opts = { baseUrl, authProvider }
        return new ApiClient(opts);
    }

    private constructor(clientOptions: ApiClientOptions) {
        if (!clientOptions.baseUrl || clientOptions.baseUrl === "") {
            throw Error("Must set the baseUrl on ApiClientOptions")
        }
        this.httpClient = HTTPClientFactory.createWithAuthenticationProvider(clientOptions.authProvider)
        this.options = clientOptions

    }

    public async fetchChannelConnections(): Promise<SubscriberInfo[]> {
        const url = this.getUrl('channel_connections')
        return this.get<SubscriberInfo[]>(url)
    }
    private getUrl(path: string) {
        return urlJoin([this.options.baseUrl, path])
    }

    private async get<TResponse>(request: RequestInfo): Promise<TResponse> {
        const options: RequestInit = {
            method: RequestMethod.GET
        };
        const context = await this.send(options, request, (status: number) => status < 400)
        return await context.response!.json() as TResponse
    }

    private async post<TResponse>(request: RequestInfo, headers: HeadersInit, body: any): Promise<TResponse> {
            const options: RequestInit = {
                method: RequestMethod.POST,
                headers: headers,
                body: body
            };
            const context = await this.send(options, request, (status: number) => status < 400)
            return await context.response!.json() as TResponse
    }

    private async postNoContent(request: RequestInfo, headers: HeadersInit = {}) {
        const options: RequestInit = {
            method: RequestMethod.POST,
            headers: headers,
        };
        await this.send(options, request, (status: number) => status === 204)
    }

    private async send(options: RequestInit, request: RequestInfo, expectedStatus: (status: number) => boolean): Promise<Context> {
        let error: Error|undefined
        try {
            const context = await this.httpClient.sendRequest({ request, options })

            if (context.response) {
                if (expectedStatus(context.response.status)) {
                    return context
                }
                console.error(`${options.method} ${request} HTTP error: ${context.response.status} ${context.response.statusText}`)
                const clientError = new ClientError("HTTP Error", context.response.status)
                clientError.response = context.response
                error = clientError
            } else {
                error = new ClientError("Unknown error")
            }
        } catch (error) {
            console.error(`${options.method} ${request} failed`, error)
            throw error
        }
        throw error
    }

}

export class ClientError extends Error {
    public statusCode: number;
    public code: string | null;
    public response: Response | null;

    public constructor(message: string, statusCode: number = -1) {
        super(message)
        this.statusCode = statusCode;
        this.code = null;
        this.response = null
    }
}

