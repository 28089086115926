import { Link, PanelType, Persona } from 'office-ui-fabric-react';
import React from 'react';
import { AppPanel } from '../AppPanel';

interface MeControlProps {
    isMeOpen: boolean
    logOut: () => void
    hideMe: () => void
    givenName: string
    familyName: string
    userName: string
    userImageUrl?: string
}

export const MeControl: React.FC<MeControlProps> = (props: MeControlProps) => {
    const {
        isMeOpen,
        logOut,
        hideMe,
        userName,
        givenName,
        familyName,
        userImageUrl
    } = props;


    const userFullName = `${givenName} ${familyName}`

    return (
        <AppPanel
            isOpen={isMeOpen}
            type={PanelType.smallFixedFar}
            onDismiss={hideMe}
            hasCloseButton={true}
            isContentFullBleed={false}
            componentId="meControl"
            headerText='My Account'
            closeButtonAriaLabel='Close'
        >
            <div>
                <Persona
                    imageUrl={userImageUrl ? URL.createObjectURL(userImageUrl!) : undefined}
                    imageAlt={userFullName}
                    text={userFullName}
                    secondaryText={userName}
                />
            </div>
            <div>
                <Link onClick={logOut}>Sign out</Link>
            </div>
        </AppPanel>
    );
}
