import { AzureAdClientOptions } from "./azure-ad";


interface ClientOptions extends AzureAdClientOptions {
    apiScopes: Array<string>,
    graphScopes: Array<string>,
    apiBaseUrl: string
}

export function clientConfig(): ClientOptions {

    return {
        authority: 'https://login.microsoftonline.com/793726a6-3860-463d-9c8b-8cd7ea73e525',
        clientId: 'd0db7d18-b23c-4c93-8c71-3be1ddda2364',
        graphScopes: ['https://graph.microsoft.com/User.ReadBasic.All'],
        apiScopes: ["api://25d95398-d031-4fe9-923e-4247ccdf5cbb/Subscribers.ReadWrite", "api://25d95398-d031-4fe9-923e-4247ccdf5cbb/Directory.ReadWrite"],
        redirectUrl: process.env.PUBLIC_URL,
        postLogoutRedirectUri: process.env.PUBLIC_URL,
        apiBaseUrl: `${process.env.PUBLIC_URL}/api`,
    }
}