import { combineReducers } from 'redux';
import { SubscriberInfo } from '../../models';
import { actions } from '../types';
import { ApiResult } from './ApiResult';
import createApiReducer from './createApiReducer';

interface MeetingsState {
    subscribers: ApiResult<SubscriberInfo[]>
}

export default combineReducers({
    subscribers: createApiReducer(actions.fetchChannelConnections),
})



