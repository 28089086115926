import { combineReducers } from 'redux';
import { actions } from '../types';
import createApiReducer from './createApiReducer';

// function auth(state: ApiResult<AuthResponse> = ApiResult.default<AuthResponse>(), action: Actions): ApiResult<AuthResponse> {

//     switch (action.type) {
//         case getType(actions.authResponse): {
//             return ApiResult.success<AuthResponse>(action.payload)
//         }
//     }

//     return state
// }



export default combineReducers({
  account: createApiReducer(actions.createAuthedAccount)  
})



