
import { Client, ResponseType } from '@microsoft/microsoft-graph-client';
import { ImplicitMSALAuthenticationProvider } from "@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider";
import { MSALAuthenticationProviderOptions } from '@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions';
import { User } from '@microsoft/microsoft-graph-types';
import { UserAgentApplication } from 'msal';

export type GraphClient = ReturnType<typeof createGraphClient>

export function createGraphClient(msalApplication: UserAgentApplication, scopes: string[]) {

    const msalOptions = new MSALAuthenticationProviderOptions(scopes);
    const msalProvider = new ImplicitMSALAuthenticationProvider(msalApplication, msalOptions);

    const client = Client.initWithMiddleware({
        debugLogging: true,
        authProvider: msalProvider,
    });

    const graph = {
        getMe,
        getPhoto,
        client,
        scopes,
        msalProvider
    }

    return graph

    async function getMe() {
        return get<User>("/me")
    }
    async function getPhoto(): Promise<Blob> {
        return await client
                        .api("/me/photo/$value")
                        .version("beta") // v1.0 uses exchange profile not AAD
                        .responseType(ResponseType.BLOB)
                        .get()
    }


    async function get<T>(path: string) {
        const resource: T = await client.api(path).get()
        return resource
    }
}

